<template>
  <YoTable
    :api-endpoint="apiEndpoint"
    :title-tbl="this.$t('COMPANY OWN')"
    :sub-title-tbl="this.$t('Agen')"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label="
      this.$t('KeteranganTable') + '~' + this.$t('Form Land Vehicle') + '~'
    "
    bottom-icon="GitMergeIcon"
    info-page-ability="Manage (All / Agen All)."
    placeholder-search="Nama Agen"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable
  },
  data() {
    return {
      apiEndpoint: 'agen',
      fields: [
        {
          searchModel: 'group.name',
          search: {
            type: 'text'
          },
          minWidth: '130px',
          key: 'group.name',
          label: 'GROUP',
          placeholder: '',
          sortable: true,
          input: { type: 'input-noarea', disabled: true }
        },
        {
          searchModel: 'name_agen',
          search: {
            type: 'text'
          },
          key: 'name_agen',
          label: 'Name Agen',
          placeholder: 'Name',
          sortable: true,
          input: { type: 'input-noarea' }
        },

        {
          searchModel: 'full_name_agen',
          search: {
            type: 'text'
          },
          key: 'full_name_agen',
          label: 'Full Name Agen',
          placeholder: 'Full Name Agen',
          sortable: true,
          input: { type: 'input-noarea' }
        },
        // {
        //   searchModel: 'dm_personal.full_name',
        //   search: {
        //     type: 'text',
        //   },
        //   key: 'dm_personal.full_name',
        //   columnKey: 'dm_personal_id',
        //   label: 'Penanggung Jawab',
        //   sortable: true,
        //   input: {
        //     type: 'select',
        //     requestAPI: 'allow',
        //     unique1: 'no_ktp',
        //     // unique2: 'capacity',
        //     optionsApiEndpoint: 'dm_personal',
        //     resolveOptionsResponse(response) {
        //       return response.data.dm_personals.map(value => ({ label: `${value.full_name} - No Ktp : ${value.no_ktp}`, value: value.id }))
        //     },
        //     reduce({ value }) {
        //       return value // as ID DATA
        //     },
        //   },
        // },
        {
          searchModel: 'user.full_name',
          search: {
            type: 'text'
          },
          key: 'user.full_name',
          label: 'Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true }
        },
        {
          searchModel: 'user.phone',
          search: {
            type: 'number'
          },
          key: 'user.phone',
          label: 'Phone of Created BY',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: { type: 'input-noarea', disabled: true }
        },
        {
          searchModel: 'created_at',
          search: {
            type: 'date'
          },
          key: 'created_at',
          label: 'Created AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          }
        },
        {
          searchModel: 'updated_at',
          search: {
            type: 'date'
          },
          key: 'updated_at',
          label: 'Updated AT',
          sortable: true,
          placeholder: 'Otomatis terisi',
          input: {
            type: 'date',
            format: 'D MMMM YYYY - hh:mm A',
            disabled: true
          }
        }
      ]
    }
  },
  computed: {
    newDataTemplate() {
      return {
        name_agen: '',
        full_name_agen: ''
      }
    }
  },
  methods: {
    moment,
    resolveFetchResponse(response) {
      return response.data.agens
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    }
  }
}
</script>
