var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('YoTable', {
    attrs: {
      "api-endpoint": _vm.apiEndpoint,
      "title-tbl": this.$t('COMPANY OWN'),
      "sub-title-tbl": this.$t('Agen'),
      "fields": _vm.fields,
      "new-data-template": _vm.newDataTemplate,
      "resolve-fetch-response": _vm.resolveFetchResponse,
      "resolve-fetch-url": _vm.resolveFetchUrl,
      "bottom-label": this.$t('KeteranganTable') + '~' + this.$t('Form Land Vehicle') + '~',
      "bottom-icon": "GitMergeIcon",
      "info-page-ability": "Manage (All / Agen All).",
      "placeholder-search": "Nama Agen"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }